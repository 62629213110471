@import 'bootstrap/dist/css/bootstrap.css';
$primary: #0099dd;
$success: #92d343;
$secondary-text: #9a9a9c;
.user {
    font-family: Arial,sans-serif!important;
    display: flex;
    flex-flow: row;
    height: 100vh;
    
    .secondary-text {
        color: $secondary-text!important;
    }

    button {
        display: inline-block;
        font-weight: 400;
        line-height: 1.5;
        text-align: center;
        text-decoration: none;
        vertical-align: middle;
        cursor: pointer;
        -webkit-user-select: none;
        user-select: none;
        background-color: transparent;
        border: 1px solid transparent;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        border-radius: 0.25rem;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }

    .btn {
        &-primary {
            color: #fff;
            background-color: #0d6efd;
            border-color: #0d6efd;
            &:active, &:hover {
                background-color: #0B5ED7;
            }
        }
        &-danger {
            background-color: #dc3545;
            border-color: #dc3545;
        }
        &-small {
            padding: 0.25rem 0.5rem;
            font-size: 0.875rem;
            border-radius: 0.2rem;
        }
    }

    .side-bar {
        width: 200px;
        border-right: solid 2px #e3ebf6;

        header {
            display: flex;
            height: 100px;
            padding: 0 1.5em;
            align-items: center;
            border-bottom: solid 2px #edf2f8;
            flex-direction: column;
            justify-content: center;

            img {
                width: 100%;
                height: 20%;
            }
        }
        .main {
            text-align: center;
            padding: 1.5em;

            small {
                font-family: inherit;
                font-size: 10px;
            }

            .btn-download {
                @extend .btn-primary;
                @extend .btn-small;
                margin: 1.5em 0;
                width: 100%;
            }
        }
    }

    .content {
        display: flex;
        flex-flow: column;
        flex: 1;
        background-color: #fafbfd;

        header {
            display: flex;
            flex-flow: row;
            margin-top: 0.5em;
            height: 100px;
            padding: 0 1.5em;
            padding-top: 0.5em;
            justify-content: space-between;
            color: $secondary-text!important;
            
            legend {
                color: #212529;
                font-weight: bold;
            }

            .btn-logout {
                color: inherit;
                font-weight: 400;
                text-decoration: none;
                &:active, &:hover {
                    background-color: transparent;
                }
            }
        }
        hr {
            margin-top: 0;
            margin-left: 1.5em;
            margin-right: 1.5em;
            padding: 1px 0!important;
            background-color: #e3ebf6;
        }
        .main {
            overflow: scroll;
            padding: 0 1.5em;
            flex: 1;

            .btn-add {
                @extend .btn-primary;
                @extend .btn-small;
                margin: 1.5em 1.5em;
                width: 100px;
            }
        }
    }
    .dropzone {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2.5rem;
        border-width: 2px;
        border-radius: 2px;
        border-color: #eeeeee;
        border-style: dashed;
        background-color: #fafafa;
        color: #bdbdbd;
        outline: none;
        transition: border .24s ease-in-out;
    }
    .non-link{
        text-decoration: none;
        color: inherit;
    }
}