@import '../../styles.scss';
.user {
.item {
    display: flex;
    flex-flow: row;
    padding: 2em 2em;
    background-color: #fff;
    border-radius: 1.5%;
    box-shadow: 1px 1px 1px 1px #e1e8f0;

    &-details {
        flex: 1;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        padding: 0px 10px;
    }

    &-name {
        font-weight: bold;
    }

    &-actions {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        min-width: 140px;
        width: 15%;
        max-width: 100px;

        .btn-edit {
            @extend .btn-primary;
            @extend .btn-small;
            width: 100%;
            margin-bottom: 1em;
        }
        .form-check-input:checked {
            background-color: $success;
            border-color: $success;
            + .form-check-label {
                color: $success;
            }
        }
        .form-check-label {
            color: $secondary-text;
        }
    }

    .form-switch {
        display: inline-block;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
    }
      
    .form-switch i {
        position: relative;
        display: inline-block;
       margin-right: .5rem;
        width: 46px;
        height: 26px;
        background-color: #e6e6e6;
        border-radius: 23px;
        vertical-align: text-bottom;
        transition: all 0.3s linear;
    }
      
    .form-switch i::before {
        content: "";
        position: absolute;
        left: 0;
        width: 42px;
        height: 22px;
        background-color: #fff;
        border-radius: 11px;
        transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
        transition: all 0.25s linear;
    }
      
    .form-switch i::after {
        content: "";
        position: absolute;
        left: 0;
        width: 22px;
        height: 22px;
        background-color: #fff;
        border-radius: 11px;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
        transform: translate3d(2px, 2px, 0);
        transition: all 0.2s ease-in-out;
    }
      
    .form-switch:active i::after {
        width: 28px;
        transform: translate3d(2px, 2px, 0);
    }
      
    .form-switch:active input:checked + i::after { transform: translate3d(16px, 2px, 0); }
      
    .form-switch input { display: none; }
      
    .form-switch input:checked + i { background-color: #4BD763; }
      
    .form-switch input:checked + i::before { transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0); }
      
    .form-switch input:checked + i::after { transform: translate3d(22px, 2px, 0); }
}
}