@import 'bootstrap/dist/css/bootstrap.css';
$primary: #0099dd;
$success: #92d343;
$secondary-text: #9a9a9c;
.marketplace {
    font-family: Arial,sans-serif!important;
    //display: flex;
    //flex-flow: row;
    height: 100vh;

    .banner {
        height: 45%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
    }
    
    .banner-text {
        text-align: center;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
    }

    .content {
        background-color: #ffffff;
        margin: 25px 50px;

        header{
            .header{
                /* FOR HORIZONTAL FILTER SCROLL
                max-height: 90px;
                display: inline-flex;
                flex-direction: column;
                overflow-y: auto;
                width: 100%;
                align-content: stretch;
                justify-content: center; */

                /* FOR VERTICAL FILTER SCROLL*/
                max-height: 100px;
                display: inline-flex;
                flex-direction: row;
                overflow-x: auto;
                width: 100%;
                align-content: flex-start;
                justify-content: center; 

                position: relative;
                height: auto;
                min-height: 60px;
                flex-wrap: wrap;
                text-align: center;
                margin-bottom: 10px;
                
                top: 50%;
                left: 50%;
                transform: translate(-50%, -0%);
                color: white;
                align-items: center;

                .btn-filter{
                    border:none;
                    border: solid 2px #edf2f8;
                    border-radius: 10%;
                    background: rgba(255, 255, 255,0);
                    margin: 10px;
                    height: 47%;
                }
                
                .btn-filter:hover {
                    //background:rgb(221, 221, 221);
                    //color:white; 
                    transform: scale(1.09); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
                    z-index: 100;
                }

                .filter-active {
                    background:rgb(221, 221, 221);
                }
                
                
            }
            
            
        }

        div.container4 {
            height: 10em;
            position: relative;
            width: 100%;
        }
        div.container4 p {
            margin: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-right: -50%;
            transform: translate(-50%, -50%) }

        .main {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            .press {
                display: block;  /* Remove bullet points; allow greater control of positioning */
                padding: 0;      /* Override defaults for lists */
                margin: 0;       /* Override defaults for lists */
                width: 100%;     /* Get the row full width */

                li {
                    display: inline-block; /* Get all images to show in a row */
                    width: 25%;            /* Show 4 logos per row */
                    text-align: center;    /* Centre align the images */
                    height: 500px;
                    max-height: 500px;

                    .card{
                        flex: 1 0 21%; /* explanation below */
                        margin: 10px;
                        height: 96%;
                        //min-height: 300px;
                        //max-height: 500px;
                        width: auto;
                        //min-width: 50%;
                        //max-width: 30%;
                        border-radius: 3%;
                        max-height: 480px;
                        overflow: hidden;
        
                        .card-banner {
                            //background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../../../assets/images/login_bg.jpg);
                            height: 60%;
                            max-height: 60%;
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: cover;
                            position: relative;
                            display: flex;
                            border-top-left-radius: 3%;
                            border-top-right-radius: 3%;
                        }
            
                        .card-body{
                            position: relative;
                            text-align: center;
                            max-height: 300px;
                            overflow: auto;

                            /*.description{
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 2; /* number of lines to show 
                                -webkit-box-orient: vertical;
                            }*/

                            .read-more-animation{
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 50; /* number of lines to show */
                                -webkit-box-orient: vertical;
                                color: #6c757d !important;
                            }
                        }

                        .card-footer{
                            position: relative;
                            width: 100%;
                            background: #ffffff;
                            border: none;
                            text-align: center;
                            
                            .category{
                                border:none;
                                border-radius: 15%;
                                background: #edf2f8;
                                margin: 10px;
                                color:rgb(112, 112, 112);
                                cursor: default;
                                font-size: 11px;
                            }
                        }

                        .card-footer-hidden{
                            display: none;
                        }
                    }

                    .card:hover {
                        transform: scale(1.05); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
                        z-index: 100;
                    }

                    .read-more-link{
                        font-size: small;
                        cursor: pointer;
                    }
                }
            }

            .the-banner {
                //background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../../../assets/images/login_bg.jpg);
                height: 290px;
                max-height: 290px;
                min-height: 290px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                position: relative;
                display: flex;
                border-top-left-radius: 3%;
                border-top-right-radius: 3%;
            }

            .card:hover {
                transform: scale(1.05); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
                z-index: 100;
            }

            .footer{
                position: relative;
                width: 100%;
                background: #ffffff;
                border: none;
                text-align: center;
                
                .category{
                    border:none;
                    border-radius: 15%;
                    background: #edf2f8;
                    margin: 10px;
                    color:rgb(112, 112, 112);
                    cursor: default;
                    font-size: 11px;
                }
            }

            .card-body{
                text-align: center;
            }

            .non-link{
                text-decoration: none;
                color: inherit;
            }
            
            @media (max-width: 960px) and (min-width: 501px) {
                .press li { width: 50%; } /* Show 2 logos per row on medium devices (tablets, phones in landscape) */
            }
            
            @media (max-width: 500px) {
                .press li { width: 100%; } /* On small screens, show one logo per row */
            }

            
            
        }
    }

    
}